import { SupabaseClient } from '@supabase/supabase-js'

import { Database, TablesUpdate } from '@/supabase'

interface updateSettingsArgs {
  client: SupabaseClient<Database>
  userId: string
  settings: TablesUpdate<'user_settings'>
}

const updateSettings = ({ client, settings, userId }: updateSettingsArgs) => {
  return client
    .schema('public')
    .from('user_settings')
    .update(settings)
    .eq('user_id', userId)
}

export default updateSettings
