import { useQuery } from '@tanstack/react-query'

import { browserClient } from '@/supabase'

import { QueryKeys } from '../../query-key'

const useGetAuthUser = () => {
  const supabase = browserClient()

  return useQuery({
    queryKey: [QueryKeys.GetAuthUser] as const,
    queryFn: async () => {
      return supabase.auth.getUser()
    },
  })
}

export default useGetAuthUser
