import { SupabaseClient } from '@supabase/supabase-js'

import { Database } from '@/supabase'

interface GetSettingsArgs {
  client: SupabaseClient<Database>
  userId: string
}

const getSettings = async ({ userId, client }: GetSettingsArgs) => {
  const response = await client
    .from('user_settings')
    .select('*')
    .eq('user_id', userId)
    .single()
  return response.data
}

export default getSettings
