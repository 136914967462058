'use client'

import { useQuery } from '@tanstack/react-query'
import { PropsWithChildren } from 'react'

import { useGetAuthUser } from '@/data/users'

import {
  NotificationProvider,
  NotificationStatus,
  useNotification,
} from '../notification'

import { QueryKeys } from './query_key'

const NotificationAuth = () => {
  const { data: auth } = useGetAuthUser()

  const { logIn, initialized, status } = useNotification()

  const userId = auth?.data.user?.id ?? ''
  const userEmail = auth?.data.user?.email ?? ''

  useQuery({
    queryKey: [QueryKeys.OnesignalLogin] as const,
    queryFn: async () => {
      if (userId && userEmail) {
        await logIn(userId, userEmail)
      }

      return null
    },
    enabled: initialized && status === NotificationStatus.Enabled && !!userId,
    retry: false,
    refetchOnWindowFocus: false,
  })

  return null
}

const PushProvider = ({
  appId,
  children,
}: PropsWithChildren<{ appId: string }>) => {
  return (
    <NotificationProvider appId={appId}>
      <NotificationAuth />
      {children}
    </NotificationProvider>
  )
}

export default PushProvider
