const routes = {
  dashboard: `/`,
  history: `/history`,
  profile: `/profile`,
  onboarding: `/onboarding`,
  enableNotifications: `/enable-notifications`,
  install_app: `/profile/install-app`,
  languages: `/profile/language`,
  manage_membership: `/profile/manage-membership`,
  validate_subscription: `/profile/validate-subscription`,
  manage_life_areas: `/profile/manage-life-areas`,
  notifications: `/profile/notifications`,
  signin: '/signin',
  resources: `/resources`,
}

export default routes
