export enum LifeCategory {
  Health = 'health',
  Growth = 'growth',
  Career = 'career',
  Friends = 'friends',
  Love = 'love',
  Motivation = 'motivation',
  Recreation = 'recreation',
  Environment = 'environment',
  Finances = 'finances',
}

export enum NotificationsFrecuencies {
  Every_sunday = 'every_sunday',
  Quarterly = 'quarterly',
  Monthly = 'monthly',
}
