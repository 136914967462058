import { PWAInstallElement } from '@khmyznikov/pwa-install'
import { create } from 'zustand'

export type PWAStore = {
  installerRef: React.RefObject<PWAInstallElement>
  setInstallerRef: (ref: React.RefObject<PWAInstallElement>) => void
}

const usePWAStore = create<PWAStore>()((set) => ({
  installerRef: { current: null },
  setInstallerRef: (ref) => set({ installerRef: ref }),
}))

export default usePWAStore
