import { createBrowserClient } from '@supabase/ssr'

import config from '@/config'

import { type Database } from '../database.types'

const browserClient = () => {
  const supabase = createBrowserClient<Database>(
    config.supabase_url,
    config.supabase_anon_key,
  )

  return supabase
}

export default browserClient
