import { useMutation, useQueryClient } from '@tanstack/react-query'

import { browserClient,Tables, TablesUpdate } from '@/supabase'

import { UsersQueryKeys } from '../..'
import { updateSettings } from '../../actions'

const useUpdateSettings = () => {
  const client = browserClient()
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async ({
      userId,
      settings,
    }: {
      userId: string
      settings: TablesUpdate<'user_settings'>
    }) => {
      await updateSettings({ settings, client, userId })
    },
    onMutate(variables) {
      queryClient.setQueryData<Tables<'user_settings'>>(
        [UsersQueryKeys.GetSettings, variables.userId],
        (prev) => {
          return {
            ...(prev ?? {}),
            ...(variables.settings as Tables<'user_settings'>),
          }
        },
      )
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [UsersQueryKeys.GetSettings],
      })
    },
  })
}

export default useUpdateSettings
