import { useQuery } from '@tanstack/react-query'

import { browserClient } from '@/supabase'

import { getSettings } from '../../actions'
import { QueryKeys } from '../../query-key'
import useGetAuthUser from '../use-get-auth-user'

const useGetSettings = () => {
  const supabase = browserClient()
  const { data } = useGetAuthUser()
  const user = data?.data.user
  return useQuery({
    queryKey: [QueryKeys.GetSettings, user?.id] as const,
    queryFn: async () => {
      return getSettings({ userId: user?.id ?? '', client: supabase })
    },
    enabled: !!user,
  })
}

export default useGetSettings
