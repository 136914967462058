import { createStore } from 'zustand/vanilla'

import { LanguageOptions } from '@/app/dictionaries'
import { DictionaryType } from '@/locales/dictionaries.types'
import enDictionary from '@/locales/en.json'

export interface LanguageStore {
  language: LanguageOptions
  setLanguage: (language: LanguageOptions) => void
  dictionary: DictionaryType
  setDictionary: (dictionary: DictionaryType) => void
}

const initialValues = {
  language: 'en' as LanguageOptions,
  dictionary: enDictionary,
}

const createLanguageStore = (
  initState: Pick<LanguageStore, 'language' | 'dictionary'> = initialValues,
) => {
  return createStore<LanguageStore>()((set) => ({
    ...initState,
    setLanguage: (language) => set({ language }),
    setDictionary: (dictionary) => set({ dictionary }),
  }))
}

export default createLanguageStore
