'use client'

import { createContext, ReactNode, useContext, useRef } from 'react'
import { useStore } from 'zustand'

import { LanguageOptions } from '@/app/dictionaries'
import { DictionaryType } from '@/locales/dictionaries.types'
import { createLanguageStore,type LanguageStore } from '@/store'

export interface LanguageStoreProviderProps {
  children: ReactNode
  dict: DictionaryType
  lang: LanguageOptions
}

export type LanguageStoreApi = ReturnType<typeof createLanguageStore>

export const LanguageStoreContext = createContext<LanguageStoreApi | undefined>(
  undefined,
)

const LanguageStoreProvider = ({
  children,
  dict,
  lang,
}: LanguageStoreProviderProps) => {
  const storeRef = useRef<LanguageStoreApi>()
  if (!storeRef.current) {
    storeRef.current = createLanguageStore({
      language: lang,
      dictionary: dict,
    })
  }

  return (
    <LanguageStoreContext.Provider value={storeRef.current}>
      {children}
    </LanguageStoreContext.Provider>
  )
}

export const useLanguageStore = <T,>(
  selector: (store: LanguageStore) => T,
): T => {
  const languageStoreContext = useContext(LanguageStoreContext)

  if (!languageStoreContext) {
    throw new Error(
      `useLanguageStore must be used within LanguageStoreProvider`,
    )
  }

  return useStore(languageStoreContext, selector)
}

export default LanguageStoreProvider
